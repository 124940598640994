import React, { useState } from 'react';
import './ChessBoard.css';

const initialBoardSetup = [
  ['R', 'N', 'B', 'Q', 'K', 'B', 'N', 'R'],
  ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['p', 'p', 'p', 'p', 'p', 'p', 'p', 'p'],
  ['r', 'n', 'b', 'q', 'k', 'b', 'n', 'r'],
];

const pieceUnicode = {
  'K': '♔', 'Q': '♕', 'R': '♖', 'B': '♗', 'N': '♘', 'P': '♙',
  'k': '♚', 'q': '♛', 'r': '♜', 'b': '♝', 'n': '♞', 'p': '♟'
};

function ChessBoard() {
  const [board, setBoard] = useState(initialBoardSetup);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [turn, setTurn] = useState('white'); // Track turns

  const isMoveValid = (from, to) => {
    // Add basic move validation logic
    return true;
  };

  const handleSquareClick = (row, col) => {
    if (selectedPiece) {
      // Validate move
      if (isMoveValid(selectedSquare, { row, col })) {
        const newBoard = board.map(row => row.slice());
        newBoard[row][col] = selectedPiece;
        newBoard[selectedSquare.row][selectedSquare.col] = '';
        setBoard(newBoard);
        setTurn(turn === 'white' ? 'black' : 'white');
      }
      setSelectedPiece(null);
      setSelectedSquare(null);
    } else {
      // Select piece
      if (board[row][col] && 
          ((turn === 'white' && board[row][col].toUpperCase() === board[row][col]) ||
          (turn === 'black' && board[row][col].toLowerCase() === board[row][col]))) {
        setSelectedPiece(board[row][col]);
        setSelectedSquare({ row, col });
      }
    }
  };

  return (
    <div className="chess-board">
      {board.map((row, rowIndex) => (
        <div key={rowIndex} className="board-row">
          {row.map((piece, colIndex) => (
            <div
              key={colIndex}
              className={`square ${((rowIndex + colIndex) % 2 === 0) ? 'white' : 'black'}`}
              onClick={() => handleSquareClick(rowIndex, colIndex)}
            >
              {piece && <span className="piece">{pieceUnicode[piece]}</span>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ChessBoard;
